import React from "react";
import Index from "./pages/Index";


//Css
import "./assets/vendor/switcher/switcher.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
//import "primereact/resources/themes/lara-light-indigo/theme.css";

import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


function App() {
  return (
    <>
      <Index />
    </>
  ); 
}

export default App;
