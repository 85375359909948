import Numeral from 'numeral'; //Composant de Formatage des nombre en montant
import 'moment';    //Composant de manipulation des dates
import 'moment/locale/fr';
import moment from 'moment';

export default class AppServices {
    constructor() {
        this.state = { 
            //URL de base
            //wsurl: 'http://localhost:8797/gyma',
            wsurl: 'https://opt-api224.com/ws_gyma/gyma',
            //Utilisateur connecté
            token: localStorage.getItem('token'),
            userconnected: JSON.parse(localStorage.getItem('userconnected')),

        };
    }

 
    disconnect() {
        localStorage.removeItem('userconnected');
        localStorage.removeItem('token');
        window.location = "/";
    }

    // Retourne une liste de couleurs
    get_liste_colors() {
        return [
            "#0673c7",
            "#f07d82",
            "#0d5943",
            "#2c87b8",
            "#FF6384",
            "#36A2EB",
            "#4071a7",
            "#fcba03",
            "#a6a5a2",
            "#9cb090",
            "#52ba9b",
            "#06c1cf",
            "#275996",
            "#ff7900",
            "#003366",
            "#295380",
            "#d87a3c",
            "#36A2Eh",
            "#FFCE56",
            "#293780",
            "#086b54",
            "#FFC107",
            "#2ECC71",
        ];
    }

    // Permet de vérifier si une adresse email est correcte
    validateEmail(value) {
        return String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) != null;
    };

    // Permet de vérifier si un numéro de téléphone est correcte
    validatePhoneNumber(value) {
        return !isNaN(value) && value.length == 9 && value[0] == '6' && (function (x) { return (x | 0) === x; })(parseFloat(value))
    };


    //Retourne l'url de base du serveur
    wsurl() {
        return this.state.wsurl;
    }

    //Permet de mettre des séparateur pour les montants
    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    //Retourne l'utilisateur connecté
    get_userconnected() {
        return this.state.userconnected;
    }

    //Permet de retourner le tocken en cours
    getToken() {
        return this.state.token;
    }

     //Retourne l'établissement de l'utilisateur connecté
     get_etablissement() {
        let etabl = undefined;
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            etabl = this.state.userconnected.idSite.idEtablissement;
        }
        return etabl;
    }


    //Retourne le profil de l'utilisateur connecté
    getProfil() {
        let profil = undefined;
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            profil = this.state.userconnected.profil;
        }
        return profil;
    }

    // Affectation des variables de state
    onInputChange = (e, name, typeInput, data) => {
        const val = (e.target && e.target.value || e.value) || '';
        if (typeInput == 'fk') {
            data[`${name}`]['id'] = val;
        } else {
            data[`${name}`] = val;
        }
        return data;
    }

      // Affectation des variables de state
      onIntlTelInputChange = (e, name, data) => {
        const val = (e) || '';
        data[`${name}`] = val;
        return data;
    }


    // Affectation des variables de state
    onInputTextariaChange = (e, name,data) => {
        data[`${name}`] = e.htmlValue;
        console.log(e.htmlValue);
        return data;
    }

    
    // Affectation des variables de state
    onCalendarChange = (e, name, data) => {
        const val = (e.target.value) || '';
        data[`${name}`] = val;
        return data;
    }

    // Permet de retourner un objet dans une liste d'objet en fonction de son identifiant
    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }




    /*  ###################  ENDPOINTS DE GESTION DES ETATS            ################### */



    generer_badge(id) {
        var lien = this.wsurl() + '/etats/generer_cartes_pdf?ids=' + id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "CARTE_" + moment(new Date()).format('DDMMYYYYhhmmss') + ".pdf");
                a.click();
            }
            );
        });
    }

     // Retourne les données du tableau de bord
     get_dashboard() {
        var lien = this.wsurl() + '/etats/get_dashboard?id_user='+this.get_userconnected().id;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }


    /*###################  FIN ENDPOINTS DE GESTION DES ETATS         ################### */



    /*  ###################  ENDPOINTS DE GESTION DES STUIDOS            ################### */

    //Ajouter et modifier un site
    save_site(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/site/add';
        } else {
            lien = this.wsurl() + '/site/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des users
    get_liste_site() {
        var lien = this.wsurl() + '/site/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data.data;
        }).catch((error) => {
            return error
        })
    }

    //Suppression d'un compte
    delete_site(data) {
        var lien = this.wsurl() + '/site/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES STUIDOS         ################### */




    /*  ###################  ENDPOINTS DE GESTION DES COMPTES            ################### */

    //Update password
    update_password(data, token) {
        var lien = this.wsurl() + '/compte/update';
        let methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    //Ajouter et modifier un user
    save_compte(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/compte/add';
        } else {
            lien = this.wsurl() + '/compte/update';
            methode = 'PUT';
        }
        data.iduser = '1';
        //data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }


     //Ajouter et modifier un user
     send_carte(data) {
        var lien = this.wsurl() + '/etats/send_carte?ids='+data.id;
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }

    // Retourne la liste générale des users
    get_liste_compte() {
        var lien = this.wsurl() + '/compte/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'un compte
    delete_compte(data) {
        var lien = this.wsurl() + '/compte/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    //Authentification d'un utilisateur
    get_identification(login, pwd) {
        var lien = this.wsurl() + '/compte/get_identification';
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ login: login, pwd: pwd })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES COMPTES         ################### */



    /*###################  ENDPOINTS DE GESTION DES UTILS            ################### */


    download_fichier_joint(namefile) {
        var lien = this.wsurl() + '/utils/download_attachment?namefile=' + namefile;
        window.location = lien;
    }

    download_file(namefile) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile;
        return lien
    }

    upload_file(file, namefile) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namefile', namefile);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES UTILS         ################### */




    /*  ###################  ENDPOINTS DE GESTION DES SITES            ################### */

    //Ajouter et modifier un site
    save_site(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/site/add';
        } else {
            lien = this.wsurl() + '/site/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des sites
    get_liste_site() {
        var lien = this.wsurl() + '/site/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'un site
    delete_site(data) {
        var lien = this.wsurl() + '/site/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES SITES         ################### */




    /*  ###################  ENDPOINTS DE GESTION DES POINTAGES            ################### */

    //Ajouter et modifier un pointage
    save_pointage(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/pointage/add';
        } else {
            lien = this.wsurl() + '/pointage/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        data.idEtabl=this.get_etablissement().id;
        console.log(data);
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des pointages
    get_liste_pointage(pd1,pd2,rid_compte,rid_site) {
        var lien = this.wsurl() + '/pointage/get_all?pd1='+pd1+'&pd2='+pd2+'&rid_compte='+rid_compte+'&rid_site='+rid_site;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'un pointage
    delete_pointage(data) {
        var lien = this.wsurl() + '/pointage/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    /*###################  FIN ENDPOINTS DE GESTION DES POINTAGES         ################### */



 /*  ###################  ENDPOINTS DE GESTION DES ACTIVITES            ################### */

    //Ajouter et modifier une activite
    save_activite(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/activite/add';
        } else {
            lien = this.wsurl() + '/activite/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        data.idEtabl=this.get_etablissement().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des activités
    get_liste_activite() {
        var lien = this.wsurl() + '/activite/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'une activité
    delete_activite(data) {
        var lien = this.wsurl() + '/activite/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES ACTIVITES         ################### */


/*  ###################  ENDPOINTS DE GESTION DES EXO            ################### */

    //Ajouter et modifier un exercice
    save_exercice(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/exercice/add';
        } else {
            lien = this.wsurl() + '/exercice/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        data.idEtabl=this.get_etablissement().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des exercises
    get_liste_exercice(pd1,pd2,rid_compte) {
        var lien = this.wsurl() + '/exercice/get_all?pd1='+pd1+'&pd2='+pd2+'&rid_compte='+rid_compte;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'un exercise
    delete_exercice(data) {
        var lien = this.wsurl() + '/exercice/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES EXO         ################### */




    /*  ###################  ENDPOINTS DE GESTION DES OOFRES            ################### */

    //Ajouter et modifier une offre
    save_offre(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/offre/add';
        } else {
            lien = this.wsurl() + '/offre/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des activités
    get_liste_offre() {
        var lien = this.wsurl() + '/offre/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'une activité
    delete_offre(data) {
        var lien = this.wsurl() + '/offre/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES OOFRES         ################### */



    /*  ###################  ENDPOINTS DE GESTION DES SONDAGES            ################### */

    //Ajouter et modifier une sondage
    save_sondage(data) {
        var lien = "";
        let methode = 'POST';
        if (data.id == 0) {
            lien = this.wsurl() + '/sondage/add';
        } else {
            lien = this.wsurl() + '/sondage/update';
            methode = 'PUT';
        }
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {

            return data;
        }).catch((error) => {
            return error
        })
    }



    // Retourne la liste générale des activités
    get_liste_sondage() {
        var lien = this.wsurl() + '/sondage/get_all';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${this.getToken()}`,
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        });
    }

    //Suppression d'une activité
    delete_sondage(data) {
        var lien = this.wsurl() + '/sondage/delete';
        data.iduser = this.get_userconnected().id;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${this.getToken()}`,
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
            if (response.status == 401) {
                this.disconnect();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    /*###################  FIN ENDPOINTS DE GESTION DES SONDAGES         ################### */


}