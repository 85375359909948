import React, { useRef, Component, useState } from "react";
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Steps } from 'primereact/steps';
import { SplitButton } from 'primereact/splitbutton';
import { v4 as uuidv4 } from 'uuid';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Link } from 'react-router-dom';
import { Chart } from 'primereact/chart';

const chartOptions = {
  // indexAxis: 'y',
  maintainAspectRatio: false,
  aspectRatio: .8,
  plugins: {
      legend: {
          labels: {
              color: '#495057'
          }
      }
  },
  scales: {
      x: {
          ticks: {
              color: '#495057'
          },
          grid: {
              color: '#ebedef'
          }
      },
      y: {
          ticks: {
              color: '#495057'
          },
          grid: {
              color: '#ebedef'
          }
      }
  }
};

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      nbEc: 0,
      nbVal: 0,
      nbT: 0,
      nbF: 0,
      moyFreq: 0,
      liste_st_moy_freq: [],
      showindicator: false,
    };
    this.appservice = new AppServices();
  }


  /* Natif react js (s'exécute au montage ou chargement de la page )*/
  componentDidMount() {
    this.get_dashboard();

  }

  get_dashboard() {
    this.appservice.get_dashboard().then(data => {
      this.setState({ showindicator: false });
      if (data !== undefined && data!==null) {
        this.setState({
          nbEc: data.nbEc,
          nbF: data.nbF,
          nbT: data.nbT,
          nbVal: data.nbVal,
          moyFreq: data.moyFreq,
          liste_st_moy_freq: data.liste_st_moy_freq 
          });
       
      }
    });
  }


  render() {
    let liste_label = null;
    let liste_ds_stat = null;

    if (this.state.liste_st_moy_freq !== null && this.state.liste_st_moy_freq !== undefined) {
      if (this.state.liste_st_moy_freq.length > 0) {
        liste_label = this.state.liste_st_moy_freq.map(x => {
          return x.label
        });
        liste_ds_stat = this.state.liste_st_moy_freq.map(x => {
          return x.ivalue
        });
      }
    }


    const chart_data = {
      labels: liste_label,
      datasets: [
        {
          label: 'Total',
          backgroundColor: '#A3E4D7',
          data: liste_ds_stat
      },
      ]
    };

    return (
      <>
        <div className="page-content bg-white">

          <section className="content-inner" style={{ height: 700, marginTop: 40 }}>
            <div className="container">

              <div className="p-fluid grid" style={{ marginTop: 2 }}>
                <div className="col-6 md:col-3">
                  <div style={{ textAlign: 'center', backgroundColor: '#7DCEA0', height: '85px', borderBottomWidth: '4px' }} >
                    <div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <span style={{ marginTop: 10 }} >
                          <i className="fa fa-fw fa-users" style={{ fontSize: 30, color: '#000' }} ></i> <label style={{ fontSize: '1.1em', color: "#000" }}>TOTAL COMPTE </label>
                        </span>

                      </div>
                    </div>
                    <label style={{ fontSize: '1.7em', color: "#000", fontWeight: 'bold' }}> {this.state.nbVal}</label>
                  </div>
                </div>

                <div className="col-6 md:col-3">
                  <div style={{ textAlign: 'center', backgroundColor: '#F4D03F', height: '85px', borderBottomWidth: '4px' }} >
                    <div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <span style={{ marginTop: 10 }} >
                          <i className="fa fa-fw fa-star" style={{ fontSize: 30, color: '#000' }} ></i> <label style={{ fontSize: '1.1em', color: "#000" }}>COMPTE EN ATTENTE </label>
                        </span>
                      </div>
                    </div>
                    <label style={{ fontSize: '1.7em', color: "#000", fontWeight: 'bold' }}> {this.state.nbEc}</label>
                  </div>
                </div>

                <div className="col-6 md:col-3">
                  <div style={{ textAlign: 'center', backgroundColor: '#ebbfde', height: '85px', borderBottomWidth: '4px' }} >
                    <div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <span style={{ marginTop: 10 }} >
                          <i className="fa fa-fw fa-female" style={{ fontSize: 30, color: '#000' }} ></i> <label style={{ fontSize: '1.1em', color: "#000" }}>TOTAL FEMME </label>
                        </span>
                      </div>
                    </div>
                    <label style={{ fontSize: '1.7em', color: "#000", fontWeight: 'bold' }}> {this.state.nbF}</label>
                  </div>
                </div>

                <div className="col-6 md:col-3">
                  <div style={{ textAlign: 'center', backgroundColor: '#F0B27A', height: '85px', borderBottomWidth: '4px' }} >
                    <div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <span style={{ marginTop: 10 }} >
                          <i className="fa fa-fw fa-star" style={{ fontSize: 30, color: '#000' }} ></i> <label style={{ fontSize: '1.1em', color: "#000" }}>FREQUENTATION </label>
                        </span>
                      </div>
                    </div>
                    <label style={{ fontSize: '1.7em', color: "#000", fontWeight: 'bold' }}> {this.state.moyFreq} / J</label>
                  </div>
                </div>

              </div>

              <div className="p-fluid grid" style={{ marginTop: 5 }}>

                <div className="col-12 md:col-6">
                  <i className="pi pi-chart-line"></i> Statistiques des fréquentations par mois
                  <hr />
                  <Chart options={chartOptions} responsive={true} type="bar" data={chart_data} height="300w" />

                </div>
                <div className="col-12 md:col-6">
                  <i className="pi pi-table"></i> Fréquentations par mois
                  <hr />
                  <DataTable value={this.state.liste_st_moy_freq} alwaysShowPaginator={false}
                    rows={15} dataKey="label" paginator className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" showGridlines responsiveLayout="scroll">
                    <Column field="label" header="MOIS" sortable />
                    <Column field="ivalue" header="NOMBRE" sortable style={{ width: '25%' }} />
                    <Column field="dvalue" header="TAUX" sortable style={{ width: '25%' }} />
                  </DataTable>
                </div>

              </div>

            </div>
          </section>

        </div>
      </>
    );
  }
}

