import React, { useRef, Component, useState } from "react";
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { SplitButton } from 'primereact/splitbutton';
import { Link } from 'react-router-dom';
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from 'primereact/inputtextarea';
import { Editor } from 'primereact/editor';


export default class Offre extends Component {
  constructor() {
    super();
    this.state = {
      liste_data: [],
      selectedData: null,
      formData: {
        id: 0,
        libelle: "",
        description: "",
        couverture: "",
        tarif: 0,
        idEtablissement: { id: 0 }
      },
      submit: false,
      showindicator: false,
      modif_data: false,
      visible_dlg_delete: false,
      visible_dlg_form: false,
      visible_dlg_toogle: false,
    };

    this.edit_data = this.edit_data.bind(this);
    this.conf_delete_data = this.conf_delete_data.bind(this);
    this.toogleDlg = this.toogleDlg.bind(this);
    this.appservice = new AppServices();
  }


  /* Natif react js (s'exécute au montage ou chargement de la page )*/
  componentDidMount() {
    this.get_liste_data();
  }



  /* Récupération de la liste globale des utilisateurs */
  get_liste_data() {
    this.setState({ showindicator: true });
    this.appservice.get_liste_offre().then(data => {
      this.setState({ liste_data: data, showindicator: false });
    });
  }

  /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
  edit_data(data) {
    if (data !== null && data !== undefined) {
      this.setState({ formData: data });
      this.setState({ modif_data: true });
      this.setState({ visible_dlg_form: true });
      this.setState({ selectedData: data });
    }
  }

  /* Enregistrer les informations du formulaire region */
  enreg_data() {
    this.setState({ submit: true });
    if (this.state.formData.libelle == "" || this.state.formData.description == "") {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
    } else {
      this.appservice.save_offre(this.state.formData).then(reponse => {
        if (reponse != undefined && reponse != null) {
          this.resultat(reponse);
        }
      });
    }
  }



  /* Fonction d'affichage des messages retournés suite aux actions serveurs */
  resultat(rep) {
    if (rep.code == 200) {
      this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
      this.annuler();
      this.get_liste_data();
    } else {
      this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
    }
    this.setState({ submit: false });
    this.setState({ showindicator: false });
  }


  /* Annuler l'opération en cours et vider le formulaire */
  annuler() {
    this.setState({
      formData: {
        id: 0,
        libelle: "",
        description: "",
        couverture: "",
        tarif: 0,
        idEtablissement: this.appservice.get_etablissement()
      },
    });
    this.setState({ showindicator: false, visible_dlg_form: false });
  }

  /* Affichage de la boite de dialogue de confirmation de suppression */
  conf_delete_data(data) {
    this.setState({ visible_dlg_delete: true });
    this.setState({ selectedData: data });
  }

  /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle commune */
  toogleDlg() {
    this.annuler();
    this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
  }

  /* Zone gauche de la toolbar */
  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 120, marginRight: 10 }} />
          <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 130 }} />
        </div>
      </React.Fragment>
    )

  }

  /* Zone droite de la toolbar */
  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} style={{ width: 115 }} />}
      </React.Fragment>
    )
  }


  /* Colonne Action du tableau contenant la liste des utilisateurs */
  actionTemplate(rowData, column) {
    const items = [
      {
        label: 'Modifier',
        icon: 'pi pi-pencil',
        command: (e) => {
          this.edit_data(rowData);
        }
      },
      {
        label: 'Supprimer',
        icon: 'pi pi-times',
        command: (e) => {
          this.conf_delete_data(rowData);
        }
      }
    ]
    return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
  }

  delete_data() {
    if (this.state.selectedData == null || this.state.selectedData == undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
    } else {
      this.setState({ showindicator: true });
      this.appservice.delete_offre(this.state.selectedData).then(rep => this.resultat(rep));
    }
  }


  /* Bloc visuel (propre à React) 225 07 07 38 62 49  */
  render() {
    return (
      <>

        <div className="page-content bg-white">
          {/* <PageTitle parentTitle="Pages" activePage="Tableau de bord" /> */}



          <section className="content-inner" style={{ height: 1000 }}>

            <div className="container" >
              {/* Toast pour l'afficahge des messages de dialogue */}
              <div style={{ marginTop: 40 }}>
                <Toast ref={(el) => this.toast = el} position="center" />

                <Dialog header="Confirmation" visible={this.state.visible_dlg_delete} style={{ width: '350px' }} modal={true} footer={<div>
                  <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_dlg_delete: false });
                    this.delete_data();
                  }} label="Oui" />
                  <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_delete: false })} label="Annuler" className="p-button-secondary" />
                </div>} onHide={() => this.setState({ visible_dlg_delete: false })}>
                  <div>
                    <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                    Voulez vous vraiment supprimer la ligne sélectionnée ?
                  </div>
                </Dialog>

                {this.state.visible_dlg_form == false ?
                  <>
                    {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                    <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                    {/* Tableau contenant la liste des comptes existants dans la base */}
                    <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                      paginator rows={10} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers tdLessPadding" showGridlines
                      selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                      emptyMessage="No data found" selectionMode="single"
                      onRowClick={e => this.setState({ selectedData: e.data }, () => {
                      })}>
                      <Column header="No" body={(rowData, props) => {
                        return <div>
                          <span >{props.rowIndex + 1}</span>
                        </div>
                      }} style={{ width: '3%' }} />
                      <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                      <Column field="libelle" header="Offre" filter={true} filterMatchMode='contains' />
                      <Column field="code" header="Code" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                      <Column field="tarif" header="Tarif" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                    </DataTable>
                  </>
                  :
                  <>
                    <nav aria-label="breadcrumb" className="breadcrumb-row">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"#"}>Offre</Link></li>{" "}
                        <li className="breadcrumb-item active" aria-current="page">{this.state.modif_data == false ? 'Ajout' : 'Modification'}</li>
                      </ul>
                    </nav>
                    <hr />
                    <div className="p-fluid grid" style={{ marginTop: 5 }}>

                      <div className="col-12 md:col-5">
                        <div className="p-fluid grid formgrid" >
                          <div className="field col-3 md:col-3">
                            <label htmlFor="email1">Offre  *</label>
                          </div>
                          <div className="field col-9 md:col-9">
                            <InputText id="name" value={this.state.formData.libelle}
                              onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelle', '', this.state.formData) })}
                              required autoFocus className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelle })} />
                            {this.state.submit && !this.state.formData.libelle && <small className="p-invalid" style={{ color: 'red' }}>L'activité est obligatoire.</small>}
                          </div>

                          <div className="field col-3 md:col-3">
                            <label htmlFor="email1">Code  *</label>
                          </div>
                          <div className="field col-9 md:col-9">
                            <InputText id="name" value={this.state.formData.code}
                              onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'code', '', this.state.formData) })}
                              required className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.code })} />
                            {this.state.submit && !this.state.formData.code && <small className="p-invalid" style={{ color: 'red' }}>Le code est obligatoire.</small>}
                          </div>

                          <div className="field col-3 md:col-3">
                            <label htmlFor="email1">Tarif  *</label>
                          </div>
                          <div className="field col-9 md:col-9">
                            <InputNumber id="name" value={this.state.formData.tarif}
                              onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'tarif', '', this.state.formData) })}
                              required className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.tarif })} />
                            {this.state.submit && !this.state.formData.tarif && <small className="p-invalid" style={{ color: 'red' }}>Le tarif est obligatoire.</small>}
                          </div>

                        </div>
                      </div>
                      <div className="col-12 md:col-7">
                        <label htmlFor="email1">Deescription  *</label>
                        <Editor id="name" value={this.state.formData.description} style={{ height: '150px' }}
                          onTextChange={(e) => this.setState({ formData: this.appservice.onInputTextariaChange(e, 'description', this.state.formData) })}
                          required className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.description })} />
                        {this.state.submit && !this.state.formData.description && <small className="p-invalid" style={{ color: 'red' }}>Le description est obligatoire.</small>}

                      </div>

                    </div>
                    <br />
                    <center>
                      <>
                        <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 200 }} onClick={(e) => this.annuler()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 200, marginLeft: 20 }} />
                      </>
                    </center>
                  </>
                }


              </div>

            </div>
          </section>

        </div>
      </>
    );
  }
}
