export const MenuListArray2 = [
    {
        title: 'Home',
        to: '/', 
    },
    {
        title: 'Présentation',
        classChange: 'sub-menu-down',
        content: [
            {
                title: 'Histoire',
                to: '#',
            },
            {
                title: 'Membres fondateurs',
                to: '#',
            },
            {
                title: 'Staf technique',
                to: '#',
            },
            {
                title: 'Joeurs',
                to: '#',
            },
            {
                title: 'Partenaires',
                to: '#',
            },
        ],
    },
    {
        title: 'Programmes',
        to: '#',
    },
    {
        title: 'Boutique',
        to: '#',
    },
    {
        title: 'Actualité',
        to: '#',
    },
    {
        title: 'Contact',
        to: '/contact-us',
    },
]