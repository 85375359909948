import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination } from "swiper";
import { IMAGES } from "../constants/theme";

const dataBlog = [
  { image: IMAGES.avatarlarge1, nom: 'Mohamed DIARRA', job: 'Ingénieur développeur', message: "Je ne pensais que nous avions de si pures talents, je crois de plus en notre capacités" },
  { image: IMAGES.avatarlarge2, nom: 'Fatou DIOP', job: 'Coiffeuse', message: "Avec tous ces équipements de dernières générations, nous pouvons atteindre nos objectifs" },
  { image: IMAGES.avatarlarge3, nom: 'Ousmane DIALLO', job: 'Comeerçant', message: "Allez! vous êtes les meilleurs, faites nous vibrer comme jamais" }
]

function ClientSlider() {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);
  return (
    <>
      <Swiper
        className="testimonial-swiper"
        // centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        speed={1500}
        pagination={{
          el: ".swiper-pagination",

          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' + className + '"> 0' + (index + 1) + "</span>"
            );
          },
        }}
        //autoplay= {{
        //    delay: 4500,
        //}}
        onSwiper={(swiper) => {
          setTimeout(() => {
            if (swiper.params !== undefined) {
              if (swiper.params.navigation !== undefined) {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }
          });
        }}
        modules={[Navigation, Pagination]}
      >
        {dataBlog.map((item, ind) => (
          <SwiperSlide key={ind}>
            <div className="testimonial-1">
              <div className="testimonial-pic">
                <img src={item.image} alt="" />
              </div>
              <ul className="testimonial-rating">
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
                <li>
                  <i className="fa-solid fa-star"></i>
                </li>
              </ul>
              <div className="testimonial-info">
                <center>
                  <p style={{ fontSize: 22, width: '60%' }}
                  // className="testimonial-text"
                  >
                    {item.message}
                  </p>
                </center>
                <h4 className="testimonial-name">{item.nom}</h4>
                <span className="testimonial-position text-title-dore">
                  {item.job}
                </span>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="num-pagination">
          <div
            className="testimonial-button-prev btn-prev"
            ref={navigationPrevRef}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div className="swiper-pagination style-1" ref={paginationRef}></div>
          <div
            className="testimonial-button-next btn-next"
            ref={navigationNextRef}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </Swiper>
    </>
  );
}
export default ClientSlider;
