import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { ProgressSpinner } from 'primereact/progressspinner';

const Connexion = () => {
  const [login, setLogin] = useState("");
  const [pwd, setPwd] = useState("");
  const [submit, setSubmit] = useState(false);
  const [msg, setMsg] = useState("");
  const [showindicator, setShowindicator] = useState("");

  const appservice = new AppServices();
  //componentDidMount
  useEffect(() => {
    return () => {

    };
  });

  const handleKeyPress = e => {
		if (e.keyCode === 13) {
			get_identification();
		}
	}

  const get_identification = () => {
    setSubmit(true);
    if (login !== '' && pwd !== '') {
      setShowindicator(true);
      appservice.get_identification(login, pwd).then(data => {
        setShowindicator(false);
        if (data !== null && data != undefined && data !== '') {
          if (data.statut === 'Actif') {
            localStorage.setItem('userconnected', JSON.stringify(data));
            window.location = "/dashboard";
          } else {
            setMsg("Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur ");
          }
        } else {
          setMsg("Login ou mot de passe incorrect !");
        }
      });

    }
  }
 


  return (
    <>
      <div
        className="coming-soon"
        data-text="MBOSSE FC"
        style={{ backgroundImage: "url(" + IMAGES.BgAppoint + ")" }}
      >
        <div className="inner-content">
          <div className="logo-header logo-dark">
            <Link to={"/"}>
              <img src={IMAGES.logo} alt="" />
            </Link>
          </div>
          <h3 className="dz-head">
            Pour accéder à votre espace <span className="text-primary">connectez-vous ici</span>
          </h3>
          <p>

          </p>

          <center>
            <div className="row" >
              <div className="col-lg-12" style={{ padding: 10 }}>
                <InputText id="name" value={login} className="form-control"
                  onChange={(e) => setLogin(e.target.value)}
                  required autoFocus style={{ width: 350 }} onKeyDown={handleKeyPress} />
                {submit && !login && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire.</small>}
              </div>
              <div className="col-lg-12" style={{ padding: 10 }}>
                <InputText id="name" value={pwd} className="form-control"
                  onChange={(e) => setPwd(e.target.value)} type="password"
                  required style={{ width: 350 }} onKeyDown={handleKeyPress} />
                {submit && !pwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
              </div>
            </div>
          </center>

          <Link className="btn btn-primary btn-skew m-r15"
            onClick={get_identification}
          >
            {" "}
            <span>CONNEXION</span>
          </Link>{" "}

          <br />

          <center>
            {showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            <label style={{ color: 'red', marginTop: 8 }} >{msg}</label>
          </center>
        </div>

      </div>

    </>
  );
};

export default Connexion;
