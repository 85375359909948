import React, { useRef, Component, useState } from "react";
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { SplitButton } from 'primereact/splitbutton';
import { Link } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import 'moment/locale/fr';
import moment from 'moment';
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';

export default class Sondage extends Component {
  constructor() {
    super();
    this.state = {
      liste_data: [],
      liste_question: [],
      selectedData: null,
      selectedQuest: null,
      formData: {
        id: 0,
        date: null,
        objectif: "",
        debut: new Date(),
        fin: new Date(),
        libelle: "",
        statut: "Ouvert",
        idEtablissement: { id: 0 }
      },
      formQuestion: {
        id: 0,
        question: "",
        typeRep: "",
        valRep: "",
        idSondage: { id: 0 },
      },
      submit: false,
      showindicator: false,
      modif_data: false,
      visible_dlg_delete: false,
      visible_dlg_form: false,
      visible_dlg_toogle: false,

      modif_quest: false,
      visible_dlg_quest: false,
      visible_dlg_del_quest: false,
      visible_dlg_quest: false,
    };

    this.edit_data = this.edit_data.bind(this);
    this.conf_delete_data = this.conf_delete_data.bind(this);
    this.toogleDlg = this.toogleDlg.bind(this);


    this.edit_quest = this.edit_quest.bind(this);
    this.conf_delete_quest = this.conf_delete_quest.bind(this);
    this.toogleDlgQuest = this.toogleDlgQuest.bind(this);




    this.appservice = new AppServices();
  }


  /* Natif react js (s'exécute au montage ou chargement de la page )*/
  componentDidMount() {
    this.get_liste_data();
  }

  /* Récupération de la liste globale des utilisateurs */
  get_liste_data() {
    this.setState({ showindicator: true });
    this.appservice.get_liste_sondage().then(data => {
      this.setState({ liste_data: data, showindicator: false });
    });
  }

  /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
  edit_data(data) {
    if (data !== null && data !== undefined) {
      this.setState({ formData: data });
      this.setState({ modif_data: true });
      this.setState({ visible_dlg_form: true });
      this.setState({ selectedData: data });
    }
  }


  /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification du questionnaire */
  edit_quest(data) {
    if (data !== null && data !== undefined) {
      this.setState({ formQuestion: data });
      this.setState({ modif_quest: true });
      this.setState({ visible_dlg_quest: true });
      this.setState({ selectedQuest: data });
    }
  }

  /* Enregistrer les informations du formulaire region */
  enreg_data() {
    this.setState({ submit: true });
    if (this.state.formData.libelle == "") {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
    } else {
      var formData = this.state.formData;
      formData.debut = moment(this.state.formData.debut, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
      formData.fin = moment(this.state.formData.fin, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
      this.appservice.save_sondage(formData).then(reponse => {
        if (reponse != undefined && reponse != null) {
          this.resultat(reponse);
        }
      });
    }
  }



  /* Fonction d'affichage des messages retournés suite aux actions serveurs */
  resultat(rep) {
    if (rep.code == 200) {
      this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
      this.annuler();
      this.get_liste_data();
    } else {
      this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
    }
    this.setState({ submit: false });
    this.setState({ showindicator: false });
  }


  /* Annuler l'opération en cours et vider le formulaire */
  annuler() {
    this.setState({
      formData: {
        id: 0,
        date: null,
        objectif: "",
        debut: new Date(),
        fin: new Date(),
        libelle: "",
        statut: "Ouvert",
        idEtablissement: this.appservice.get_etablissement()
      },
    });
    this.setState({ showindicator: false, visible_dlg_form: false });
  }

  /* Annuler l'opération en cours et vider le formulaire */
  annuler_quest() {
    this.setState({
      formData: {
        id: 0,
        question: "",
        typeRep: "",
        valRep: "",
        idSondage: { id: 0 },
      },
    });
    this.setState({ showindicator: false, visible_dlg_quest: false });
  }

  /* Affichage de la boite de dialogue de confirmation de suppression */
  conf_delete_data(data) {
    this.setState({ visible_dlg_delete: true });
    this.setState({ selectedData: data });
  }

  /* Affichage de la boite de dialogue de confirmation de suppression d'une question */
  conf_delete_quest(data) {
    this.setState({ visible_dlg_del_quest: true });
    this.setState({ selectedQuest: data });
  }

  /* Affichage de la boite de dialogue pour l'ajout d'un nouveau sondage */
  toogleDlg() {
    this.annuler();
    this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
  }

  /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle question */
  toogleDlgQuest() {
    this.annuler_quest();
    this.setState({ visible_dlg_quest: !this.state.visible_dlg_quest });
  }

  /* Zone gauche de la toolbar */
  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 120, marginRight: 10 }} />
          <Button label="Supprimer" disabled={this.state.selectedData == null} icon="pi pi-trash" className="p-button-danger" onClick={(e) => this.conf_delete_data(this.state.selectedData)} style={{ width: 130 }} />
        </div>
      </React.Fragment>
    )

  }

  /* Zone droite de la toolbar */
  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-download" className="p-button-help" onClick={this.exportCSV} style={{ width: 115 }} />}
      </React.Fragment>
    )
  }

  actionTemplateQuestion(rowData) {
    return <div className="grid" style={{ width: 90 }}>
      <div className="col"><Button title='Modifier' onClick={() => this.edit_quest(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
      <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_quest(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
    </div>
  }

  /* Colonne Action du tableau contenant la liste des utilisateurs */
  actionTemplate(rowData, column) {
    const items = [
      {
        label: 'Résultat',
        icon: 'pi pi-eye',
        command: (e) => {

        }
      },
      {
        label: 'Modifier',
        icon: 'pi pi-pencil',
        command: (e) => {
          this.edit_data(rowData);
        }
      },
      {
        label: 'Supprimer',
        icon: 'pi pi-times',
        command: (e) => {
          this.conf_delete_data(rowData);
        }
      }
    ]
    return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
  }

  delete_data() {
    if (this.state.selectedData == null || this.state.selectedData == undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
    } else {
      this.setState({ showindicator: true });
      this.appservice.delete_sondage(this.state.selectedData).then(rep => this.resultat(rep));
    }
  }

  delete_question() {
    if (this.state.selectedQuest == null || this.state.selectedQuest == undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
    } else {
      this.setState({ showindicator: true });
      this.appservice.delete_question(this.state.selectedQuest).then(rep => this.resultat(rep));
    }
  }

  /* Bloc visuel (propre à React) */
  render() {
    return (
      <>
        <Dialog header="Confirmation" visible={this.state.visible_dlg_delete} style={{ width: '350px' }} modal={true} footer={<div>
          <Button icon="pi pi-check" onClick={() => {
            this.setState({ visible_dlg_delete: false });
            this.delete_data();
          }} label="Oui" />
          <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_delete: false })} label="Annuler" className="p-button-secondary" />
        </div>} onHide={() => this.setState({ visible_dlg_delete: false })}>
          <div>
            <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
            Voulez vous vraiment supprimer la ligne sélectionnée ?
          </div>
        </Dialog>


        <Dialog header="Confirmation" visible={this.state.visible_dlg_del_quest} style={{ width: '350px' }} modal={true} footer={<div>
          <Button icon="pi pi-check" onClick={() => {
            this.setState({ visible_dlg_del_quest: false });
            this.delete_question();
          }} label="Oui" />
          <Button icon="pi pi-times" onClick={() => this.setState({ visible_dlg_del_quest: false })} label="Annuler" className="p-button-secondary" />
        </div>} onHide={() => this.setState({ visible_dlg_del_quest: false })}>
          <div>
            <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
            Voulez vous vraiment supprimer la question sélectionnée ?
          </div>
        </Dialog>


        <div className="page-content bg-white">
          {/* <PageTitle parentTitle="Pages" activePage="Tableau de bord" /> */}


          <section className="content-inner" style={{ height: 1000 }}>

            <div className="container" >
              {/* Toast pour l'afficahge des messages de dialogue */}
              <div style={{ marginTop: 40 }}>
                <Toast ref={(el) => this.toast = el} position="center" />

                {this.state.visible_dlg_form == false ?
                  <>
                    {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                    <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                    {/* Tableau contenant la liste des comptes existants dans la base */}
                    <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                      paginator rows={10} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers tdLessPadding" showGridlines
                      selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                      emptyMessage="No data found" selectionMode="single"
                      onRowClick={e => this.setState({ selectedData: e.data }, () => {
                      })}>
                      <Column header="No" body={(rowData, props) => {
                        return <div>
                          <span >{props.rowIndex + 1}</span>
                        </div>
                      }} style={{ width: '3%' }} />
                      <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '12%' }} />
                      <Column field="libelle" header="Titre" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                      <Column field="objectif" header="Objectif" filter={true} filterMatchMode='contains' />
                      <Column field="debut" header="Date démarrage" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                      <Column field="fin" header="Date fermeture" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                      <Column field="statut" header="Statut" filter={true} filterMatchMode='contains' style={{ width: '8%' }} />
                    </DataTable>
                  </>
                  :
                  <>
                    {/* Boite de dialogue d'ajout et de modification d'une question  */}
                    <Dialog visible={this.state.visible_dlg_form} style={{ width: '450px' }} header={this.state.modif_data == true ? "Modification" : "Ajout"} modal className="p-fluid" onHide={(e) => this.setState({ visible_dlg_form: false })}
                      footer={<>
                        <Button label="Fermer" icon="pi pi-times" className="p-button-secondary" style={{ width: 120 }} onClick={(e) => this.annuler_quest()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_quest()} style={{ width: 120 }} />
                      </>}>
                      <div className="p-fluid">
                        <div className="field">
                          <label htmlFor="name1">Question *</label>
                          <InputTextarea rows={2} id="name1" value={this.state.formQuestion} className={classNames({ 'p-invalid': this.state.submit && !this.state.formQuestion.question })}
                            onChange={(e) => this.setState({ formQuestion: this.appservice.onInputChange(e, 'question', '', this.state.formQuestion) })}
                            required />
                          {this.state.submit && !this.state.formQuestion.question && <small className="p-invalid" style={{ color: 'red' }}>La question est obligatoire.</small>}
                        </div>


                        <div className="field">
                          <label htmlFor="name1">Réponses possible *</label>
                          <InputTextarea rows={8} id="name1" value={this.state.formQuestion} className={classNames({ 'p-invalid': this.state.submit && !this.state.formQuestion.valRep })}
                            onChange={(e) => this.setState({ formQuestion: this.appservice.onInputChange(e, 'question', '', this.state.formQuestion) })}
                            required />
                          {this.state.submit && !this.state.formQuestion.valRep && this.state.formData.typeRep!=='Text' && <small className="p-invalid" style={{ color: 'red' }}>Les réponses possibles sont obligatoires.</small>}
                        </div>

                        <div className="field">
                          <label htmlFor="name1">Type réponse *</label>
                          <Dropdown autoWidth={false} value={this.state.formQuestion.typeRep} filter={true} filterBy='label' filterMatchMode='contains'
                            options={[
                              { value: 'Choix multiple', label: 'Choix multiple' },
                              { value: 'Choix unique', label: 'Choix unique' },
                              { value: 'Text', label: 'Text' },
                            ]}
                            onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'typeRep', '', this.state.formData) })}
                            appendTo={document.body} />
                        </div>





                      </div>
                    </Dialog>

                    <nav aria-label="breadcrumb" className="breadcrumb-row">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"#"}>Sondage</Link></li>{" "}
                        <li className="breadcrumb-item active" aria-current="page">{this.state.modif_data == false ? 'Ajout' : 'Modification'}</li>
                      </ul>
                    </nav>
                    <hr />
                    <div className="p-fluid grid formgrid" >
                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Titre *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.libelle}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'libelle', '', this.state.formData) })}
                          required autoFocus className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.libelle })} />
                        {this.state.submit && !this.state.formData.libelle && <small className="p-invalid" style={{ color: 'red' }}>Le titre est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Objectif *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.objectif}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'objectif', '', this.state.formData) })}
                          required className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.objectif })} />
                        {this.state.submit && !this.state.formData.objectif && <small className="p-invalid" style={{ color: 'red' }}>L'objectif est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Date démarrage *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <Calendar id="name" value={this.state.formData.debut}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'debut', '', this.state.formData) })}
                          required dateFormat='dd/mm/yy' showTime baseZIndex={10000} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.debut })} />
                        {this.state.submit && !this.state.formData.debut && <small className="p-invalid" style={{ color: 'red' }}>La date de démarrage est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Date fermeture *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <Calendar id="name" value={this.state.formData.fin}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'fin', '', this.state.formData) })}
                          required dateFormat='dd/mm/yy' showTime baseZIndex={10000} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.fin })} />
                        {this.state.submit && !this.state.formData.fin && <small className="p-invalid" style={{ color: 'red' }}>La date de fermeture est obligatoire.</small>}
                      </div>
                    </div>

                    <i className="fa fa-question-circle"></i> Questionnaires
                    <hr />
                    <Button icon="pi pi-plus" onClick={() => {
                      this.setState({ visible_dlg_quest: false });
                    }} label="Ajouter une question" className="p-button-success" />

                    {/* Tableau contenant la liste des comptes existants dans la base */}
                    <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_question} resizableColumns="scroll"
                      paginator rows={50} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers tdLessPadding" showGridlines
                      selection={this.state.selectedQuest} onRowSelect={e => this.setState({ selectedQuest: e.data })}
                      emptyMessage="No data found" selectionMode="single"
                      onRowClick={e => this.setState({ selectedData: e.data }, () => {
                      })}>
                      <Column header="No" body={(rowData, props) => {
                        return <div>
                          <span >{props.rowIndex + 1}</span>
                        </div>
                      }} style={{ width: '3%' }} />
                      <Column body={this.actionTemplateQuestion.bind(this)} header="Action" style={{ width: '12%' }} />
                      <Column field="question" header="Question" filter={true} filterMatchMode='contains' />
                      <Column field="typeRep" header="Type reponse" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                    </DataTable>



                    <br />
                    <center>
                      <>
                        <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 200 }} onClick={(e) => this.annuler()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 200, marginLeft: 20 }} />
                      </>
                    </center>
                  </>
                }


              </div>

            </div>
          </section>

        </div>
      </>
    );
  }
}
