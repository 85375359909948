import React, { useRef, Component, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import NewsLetter from "../elements/NewsLetter";
import PageTitle from "../elements/PageTitle";
import { Link } from "react-router-dom";
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import AppServices from '../service/AppServices';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import 'moment';
import 'moment/locale/fr'; 
import moment from 'moment';
import { InputNumber } from 'primereact/inputnumber';
import { v4 as uuidv4 } from 'uuid';
import { Password } from 'primereact/password';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


export default class Inscription extends Component {
  constructor() {
    super();
    this.state = {
      liste_agence: [],
      formData: {
        id: 0,
        chpwd: true,
        login: "",
        pwd: "",
        confpwd: "",
        nom: "",
        prenom: "",
        profil: "Guest",
        photo: "",
        telephone: "",
        email: "",
        matricule: "",
        statut: "En attente",
        adresse: "",
        dateNaiss: "",
        lieuNaiss: "",
        photo: "",
        poids: "",
        taille: "",
        sexe: "",
        photoPiece: "",
        idSite: { id: 2 }
      },

      file: null,
      urlfile: null,

      filePI: null,
      urlfilePI: null,

      showindicator: false,
      submit: false,
      submitLogin: true,
      submitEmail: true,
      msgPhone: '',
      msgEmail: '',
    };

    this.appservice = new AppServices();
  }


  /* Natif react js (s'exécute au montage ou chargement de la page )*/
  componentDidMount() {
   /*  this.appservice.get_liste_site().then(data => {
      if (data != null && data != undefined) {
        let liste = data.map(x => {
          return { value: x.id, label: x.libelle }
        });
        this.setState({ liste_agence: liste });
      }
    }); */
  }

  /* Enregistrer les informations du formulaire region */
  enreg_data() {
    this.setState({ submit: true });
    if (this.state.formData.nom == "" || this.state.formData.prenom == "" || this.state.formData.telephone == "" || this.state.formData.dateNaiss == "" || this.state.formData.lieuNaiss == "" || this.state.formData.sexe == "" || this.state.formData.email == "") {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
    }else  if (this.state.file==null || this.state.file==undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner la photo' });
    } else {
      let form = this.state.formData;
      let nomfic = this.state.formData.photo;
      let upload = false;
      if (this.state.file != null && this.state.file != undefined) {
        let name = this.state.file.name;
        nomfic = 'PHOTO_U_1' + uuidv4() + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
        upload = true;
      }

      let uploadPI = false;
      let nomficPI = this.state.formData.extrait_naiss;
      if (this.state.fileExn != null && this.state.fileExn != undefined) {
        let name = this.state.fileExn.name;
        nomficPI = 'PI_U_1' + uuidv4() + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
        uploadPI = true;
      }

      form.photo = nomfic;
      form.photoPiece = nomficPI;

      this.appservice.save_compte(form).then(reponse => {
        if (reponse != undefined && reponse != null) {
          if (reponse.code == 200) {
            if (upload === true) {
              this.fileUpload(this.state.file, nomfic);
            }
            if (uploadPI === true) {
              this.fileUpload(this.state.filePI, nomficPI);
            }
          }
          this.resultat(reponse);
        }
      });
    }
  }


  openFileUpload() {
    document.getElementById("hiddenFile").click();
  }

  openFileUploadPI() {
    document.getElementById("hiddenFilePI").click();
  }


  /* Uploader sur le serveur le logo */
  fileUpload(file, namefile) {
    this.appservice.upload_file(file, namefile).then(data => {
      if (data.code == 200) {
        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
      } else {
        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
      }
    });
  }

  /* Fonction d'affichage des messages retournés suite aux actions serveurs */
  resultat(rep) {
    if (rep.code == 200) {
      this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
      this.annuler();
    } else {
      this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
    }
    this.setState({ submit: false });
    this.setState({ showindicator: false });
  }

  /* Annuler l'opération en cours et vider le formulaire */
  annuler() {
    this.setState({
      formData: {
        id: 0,
        chpwd: true,
        login: "",
        pwd: "",
        confpwd: "",
        nom: "",
        prenom: "",
        profil: "Guest",
        photo: "",
        telephone: "",
        email: "",
        matricule: "",
        statut: "En attente",
        adresse: "",
        dateNaiss: "",
        lieuNaiss: "",
        photo: "",
        poids: "",
        taille: "",
        sexe: "",
        photoPiece: "",
        idSite: { id: 2 }
      },
      urlfile: null,
      file: null
    });

    this.setState({ showindicator: false });
  }

  /* Bloc visuel (propre à React) */
  render() {
    return (
      <>
        {/* Toast pour l'afficahge des messages de dialogue */}
        <Toast ref={(el) => this.toast = el} position="center" life={10000} />

        <div className="page-content bg-white">
          <br /><br />
          <PageTitle activePage="Inscription" parentTitle="Home" />

          <section className="content-inner-2 z-index-none" >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 col-xl-3 m-sm-b30 m-xl-b0">
                  <div className="contact-box">
                    <h3 className="contact-title">Contact</h3>
                    <p className="contact-text">
                      Remplissez le formulaire d'inscription et notre équipe vous fera un retour dans 24 heures ouvrables
                    </p>
                    <div className="widget widget_getintuch ">
                      <ul>
                        <li>
                          <i className="fa-solid fa-location-dot"></i>
                          <p>
                            832 Rue Mermoz, Dakar
                          </p>
                        </li>
                        <li>
                          <i className="fa-solid fa-phone"></i>
                          <p>+221-77-671-109</p>
                        </li>
                        <li>
                          <i className="fa-solid fa-envelope"></i>
                          <p>info@mbossefc.com</p>
                        </li>
                      </ul>
                    </div>
                    <h6 className="m-b15 text-white">Suivez-nous sur</h6>
                    <div className="dz-social-icon style-1 dark">
                      <ul>
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.facebook.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>{" "}
                        <li>
                          <Link
                            target="_blank"
                            to="https://www.instagram.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>{" "}
                        <li>
                          <Link
                            target="_blank"
                            to="https://twitter.com/"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>{" "}
                        {/* <li>
                          <Link
                            target="_blank"
                            to="https://whatsapp.com/"
                            rel="noreferrer"
                          >
                            <i className="fa-brands fa-whatsapp" />
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                    <svg
                      width="250"
                      height="70"
                      viewBox="0 0 250 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 38L250 0L210 70L0 38Z"
                        fill="url(#paint0_linear_306_1296)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_306_1296"
                          x1="118.877"
                          y1="35.552"
                          x2="250.365"
                          y2="35.552"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="1" stopColor="var(--primary)" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>



                <div className="col-md-6 col-xl-9">
                  <center>
                    <div className="field" style={{ width: 120 }} >
                      <label>PHOTO *</label>
                      <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                        <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                          <center>
                            {this.state.urlfile == null ?
                              <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                              </label></div> :
                              <img src={this.state.urlfile} style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                          </center>
                        </div>
                      </a>
                      <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          this.setState({ file: e.target.files[0] }, () => {
                            let url = URL.createObjectURL(this.state.file);
                            this.setState({ urlfile: url });
                          })
                        }
                      }} />
                    </div>
                  </center>

                  <div className="p-fluid grid formgrid" >
                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Nom *</label>
                    </div>
                    <div className="field col-9 md:col-4">


                      <InputText id="name" value={this.state.formData.nom}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                        required autoFocus style={{ height: 35}}/>
                      {this.state.submit && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                    </div>

                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Prénom *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <InputText id="name" value={this.state.formData.prenom}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'prenom', '', this.state.formData) })}
                        required style={{ height: 35}}/>
                      {this.state.submit && !this.state.formData.prenom && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                    </div>


                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Sexe *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <div className="row" style={{ marginTop: 10 }} >
                        <div className="col-6">
                          <RadioButton inputId="fem" name="fem" value={'F'} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sexe', '', this.state.formData) })} checked={this.state.formData.sexe === 'F'} />
                          <label htmlFor="fem" style={{marginLeft: 5}}>Feminin</label>
                        </div>
                        <div className="col-6">
                          <RadioButton inputId="masc" name="masc" value={'M'} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sexe', '', this.state.formData) })} checked={this.state.formData.sexe === 'M'} />
                          <label htmlFor="masc" style={{marginLeft: 5}}>Masculin</label>
                        </div>
                      </div>
                      {this.state.submit && !this.state.formData.sexe && <small className="p-invalid" style={{ color: 'red' }}>Le sexe est obligatoire.</small>}
                    </div>


                    <div className="field col-3 md:col-2">
                      <label htmlFor="name1">Date de naissance * </label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <InputMask mask="99/99/9999" id="name" value={this.state.formData.dateNaiss}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'dateNaiss', '', this.state.formData) })}
                        required style={{ height: 35}}/>
                      {this.state.submit && !this.state.formData.dateNaiss && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire</small>}
                    </div>


                    <div className="field col-3 md:col-2">
                      <label htmlFor="name1">Lieu de naissance * </label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <InputText id="name1" value={this.state.formData.lieuNaiss}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'lieuNaiss', '', this.state.formData) })}
                        required style={{ height: 35}}/>
                      {this.state.submit && !this.state.formData.lieuNaiss && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de naissance est obligatoire</small>}
                    </div>
 

                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Téléphone *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <PhoneInput
                        defaultCountry="sn" inputStyle={{width: '100%', fontSize: 14}}
                        countrySelectorStyleProps={{height: 100}} dialCodePreviewStyleProps={{height: 100}}
                        value={this.state.formData.telephone}
                        onChange={(e) => this.setState({ formData: this.appservice.onIntlTelInputChange(e, 'telephone', this.state.formData) })}
                      />
                      {/* <InputMask id="tel" mask="999999999" value={this.state.formData.telephone}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone', '', this.state.formData) })}
                        required keyfilter='pint' /> */}
                      {this.state.submit && !this.state.formData.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                    </div>
                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Adresse </label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <InputText id="name" value={this.state.formData.adresse}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adresse', '', this.state.formData) })}
                        required style={{ height: 35}}/>
                    </div>

                    <div className="field col-3 md:col-2">
                      <label htmlFor="email1">Email *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <InputText id="name" value={this.state.formData.email}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'email', '', this.state.formData) })}
                        required style={{ height: 35}}/>
                      {this.state.submit && !this.state.formData.email && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                    </div>

                    <div className="field col-3 md:col-2">
                      <label htmlFor="pwd">Mot de passe *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <Password type="password" id="pwd" value={this.state.formData.pwd}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'pwd', '', this.state.formData) })}
                        required toggleMask style={{ height: 35}} />
                      {this.state.submit && !this.state.formData.pwd && <small className="p-invalid" style={{ color: 'red' }}>Le mot de passe est obligatoire.</small>}
                    </div>

                    <div className="field col-3 md:col-2">
                      <label htmlFor="confpwd">Confirmer le mot de passe *</label>
                    </div>
                    <div className="field col-9 md:col-4">
                      <Password type="password" id="confpwd" value={this.state.formData.confpwd}
                        onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'confpwd', '', this.state.formData) })}
                        required toggleMask style={{ height: 35}} />
                      {this.state.submit && !this.state.formData.confpwd && <small className="p-invalid" style={{ color: 'red' }}>La confirmation du mot de passe est obligatoire.</small>}
                    </div>

                  </div>
                  <br />
                  <center>
                    <Button label="S'inscrire" style={{width: 300}} className="btn btn-primary btn-lg btn-skew" icon="pi pi-check" onClick={(e) => this.enreg_data()} />
                  </center>

                  {/* 

                <form
                  className="dz-form dzForm style-1"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <input
                    type="hidden"
                   
                    name="dzToDo"
                    value="Contact"
                  />
                  <div className="dzFormMsg"></div>

                  <div className="row">
                    <div className="col-lg-6" style={{padding: 10}}>
                      <div className="input-group input-line">
                        <input
                          name="dzFirstName"
                          required
                          type="text"
                         
                          placeholder="Prénom"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6" style={{padding: 10}}>
                      <div className="input-group input-line">
                        <input
                          name="dzLastName"
                          required
                          type="text"
                         
                          placeholder="Nom"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="dzEmail"
                          required
                          type="text"
                         
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-line">
                        <input
                          name="dzPhoneNumber"
                          required
                          type="text"
                         
                          placeholder="Téléphone"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="input-group input-line m-b30">
                        <textarea
                          name="dzMessage"
                          rows="5"
                          required
                         
                          placeholder="Message..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-12"> 
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"

                        className="btn btn-primary btn-lg btn-skew"
                      >
                        <span>S'inscrire</span>
                      </button>
                    </div>
                  </div>

                </form> */}





                </div>







              </div>
            </div>
          </section>

          <br />
          <br />
          <br />
          {/*  <section className="call-action style-1 footer-action">
            <div className="container">
              <NewsLetter />
            </div>
          </section> */}
        </div>
      </>
    );
  }
}
