import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";

import ClientSlider from "../components/ClientSlider";
import FitnessIdeas from "../components/FitnessIdeas";
import CounterBlog from "../components/CounterBlog";
import FitnessGoal from "../components/FitnessGoal"; 

//Components
import MainBanner from "../components/MainBanner";
import PerfectTrainers from "../components/PerfectTrainers";
import { IMAGES, SVGICON } from "../constants/theme";
import NewsLetter from "../elements/NewsLetter";
import WorkoutSlider from "../elements/WorkoutSlider";
import LatestSlider from "../elements/LatestSlider";

const Home = () => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation().pathname;

  useEffect(() => {
    const body = document.querySelector("body");  
    body.setAttribute("data-theme-color", 'color_1'); 
    localStorage.setItem("theme", "color_1");
    localStorage.setItem("themeInd", 0);
  }, [location]);
  return (
    <>
      <div className="page-content bg-white">
        <div className="main-bnr-one">
          <MainBanner isOpenModal={setOpen} />
        </div>
       {/*  <section className="content-inner about-wrapper1 about-bx1">
          <div className="container">
            <div className="row align-items-center">
              <PerfectTrainers />
            </div>
          </div>
        </section> */}
       
        <section className="content-inner portfolio-wrapper">
          <div className="portfolio-wrapper-inner">
            <div className="container-fluid  p-0">
              <WorkoutSlider />
            </div>
          </div>
          <svg
            className="shape-up"
            width="635" 
            height="107"
            viewBox="0 0 635 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M577 0L-16 107L635 45L577 0Z" fill="var(--primary)" />
          </svg>
          <svg
            className="shape-down"
            width="673"
            height="109"
            viewBox="0 0 673 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M682 0L0 56L682 109V0Z" fill="var(--primary)" />
          </svg>
        </section>
        <section
          className="content-inner-1 testimonial-wrapper1"
          data-text="FEEDBACK"
          style={{
            // backgroundImage: "url(" + IMAGES.BgImage2 + ")",
            backgroundPosition: "center",
            backgroundColor: "#fff"
          }}
        >
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-title wow fadeInUp" data-wow-delay="0.2s">
                TEMOIGNAGES
              </h5>
              <h2 className="text-title wow fadeInUp" data-wow-delay="0.4s">
                Ce que disent nos fans 
              </h2>
            </div>
            <ClientSlider />
          </div>
         
          <img
            className="svg-shape rotate-360"
            src={SVGICON.circlebigSvg1}
            alt=""
          />
          <img
            className="svg-shape-2 rotate-360"
            src={SVGICON.circlebigSvg2}
            alt=""
          />
        </section>
      
        <section className="call-action style-1 footer-action">
          <div className="container">
            <NewsLetter />
          </div>
        </section>
      </div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="X_9VoqR5ojM"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Home;
