import React, { useRef, Component, useState } from "react";
import AppServices from '../service/AppServices';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { v4 as uuidv4 } from 'uuid';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Link } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

export default class Compte extends Component {
  constructor() {
    super();
    this.state = {
      liste_agence: [],
      liste_selected_comptes: [],
      liste_data: [],
      liste_all_data: [],
      selectedData: null,
      selectedFilter: 'Tous les comptes',
      liste_profil: [
        { label: 'Administrateur', value: 'Administrateur' },
        { label: 'Guest', value: 'Guest' },
      ],
      formData: {
        id: 0,
        chpwd: true,
        login: "",
        pwd: "0000",
        nom: "",
        prenom: "",
        profil: "Guest",
        photo: "",
        telephone: "",
        email: "",
        matricule: "",
        statut: "En attente",
        adresse: "",
        dateNaiss: "",
        lieuNaiss: "",
        photo: "",
        poids: "",
        taille: "",
        sexe: "",
        photoPiece: "",
        idSite: { id: 2 }
      },

      file: null,
      urlfile: null,

      filePI: null,
      urlfilePI: null,

      submit: false,
      submitLogin: true,
      submitEmail: true,
      msgPhone: '',
      msgEmail: '',

      showindicator: false,
      modif_data: false,
      visible_dlg_delete: false,
      visible_dlg_form: false,
      visible_dlg_impression: false,
      visible_dlg_toogle: false,
    };

    this.edit_data = this.edit_data.bind(this);
    this.conf_delete_data = this.conf_delete_data.bind(this);
    this.toogleDlg = this.toogleDlg.bind(this);
    this.conf_print_data = this.conf_print_data.bind(this);
    this.conf_print_bloc = this.conf_print_bloc.bind(this);
    this.conf_toogle_data = this.conf_toogle_data.bind(this);
    this.appservice = new AppServices();
  }


  /* Natif react js (s'exécute au montage ou chargement de la page )*/
  componentDidMount() {
    this.get_liste_data();
    this.appservice.get_liste_site().then(data => {
      this.setState({ showindicator: false });
      if (data != undefined) {
        let liste = data.map(x => {
          return { value: x.id, label: x.libelle }
        });
        this.setState({ liste_agence: liste });
      }
    });
  }


  conf_print_data = (data) => {
    if (data != null && data != undefined) {
      this.setState({ selectedData: data });
      this.appservice.generer_badge(data.id);
    }
  }

  /* Affichage de la boite de dialogue de confirmation de suppression */
  conf_toogle_data(data) {
    this.setState({ visible_dlg_toogle: true });
    this.setState({ selectedData: data });
  }

  conf_print_bloc = () => {
    if (this.state.liste_selected_comptes.length <= 0) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les compte à éditer' });
    } else {
      let ids = this.state.liste_selected_comptes.map(x => {
        return x.id
      }).join("_");
      this.appservice.generer_badge(ids);
    }
  }


  /* Récupération de la liste globale des utilisateurs */
  get_liste_data() {
    this.setState({ showindicator: true });
    this.appservice.get_liste_compte().then(data => {
      this.setState({ liste_data: data, liste_all_data: data, showindicator: false });
    });
  }

  /* Remplissage du formulaire avec les informations de la ligne sélectionnée dans le cadre de la modification  */
  edit_data(data) {
    if (data !== null && data !== undefined) {
      this.setState({ formData: data });
      this.setState({ modif_data: true });
      this.setState({ visible_dlg_form: true });
      this.setState({ selectedData: data });
    }
  }

  /* Enregistrer les informations du formulaire region */
  enreg_data() {
    this.setState({ submit: true });
    if (this.state.formData.nom == "" || this.state.formData.prenom == "" || this.state.formData.telephone == "" || this.state.formData.dateNaiss == "" || this.state.formData.lieuNaiss == "" || this.state.formData.sexe == "" || this.state.formData.email == "") {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner tous les champs obligatoires' });
    } else if (this.state.file == null || this.state.file == undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez renseigner la photo' });
    } else {
      let form = this.state.formData;
      let nomfic = this.state.formData.photo;
      let upload = false;
      if (this.state.file != null && this.state.file != undefined) {
        let name = this.state.file.name;
        nomfic = 'PHOTO_U_1' + uuidv4() + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
        upload = true;
      }

      let uploadPI = false;
      let nomficPI = this.state.formData.extrait_naiss;
      if (this.state.fileExn != null && this.state.fileExn != undefined) {
        let name = this.state.fileExn.name;
        nomficPI = 'PI_U_1' + uuidv4() + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
        uploadPI = true;
      }

      form.photo = nomfic;
      form.photoPiece = nomficPI;

      this.appservice.save_compte(form).then(reponse => {
        if (reponse != undefined && reponse != null) {
          if (reponse.code == 200) {
            if (upload === true) {
              this.fileUpload(this.state.file, nomfic);
            }
            if (uploadPI === true) {
              this.fileUpload(this.state.filePI, nomficPI);
            }
          }
          this.resultat(reponse);
        }
      });
    }
  }


  openFileUpload() {
    document.getElementById("hiddenFile").click();
  }

  openFileUploadPI() {
    document.getElementById("hiddenFilePI").click();
  }


  /* Uploader sur le serveur le logo */
  fileUpload(file, namefile) {
    this.appservice.upload_file(file, namefile).then(data => {
      if (data.code == 200) {
        //this.toast.show({ severity: 'success', summary: 'Success', detail: 'Chargement du fichier sur le serveur effectué avec succès' });
      } else {
        //this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload du fichier sur le serveur' });
      }
    });
  }

  /* Fonction d'affichage des messages retournés suite aux actions serveurs */
  resultat(rep) {
    if (rep.code == 200) {
      this.toast.show({ severity: 'success', summary: 'Success', detail: rep.contenu });
      this.annuler();
    } else {
      this.toast.show({ severity: 'error', summary: 'Error', detail: rep.contenu });
    }
    this.setState({ submit: false });
    this.setState({ showindicator: false });
  }


  /* Annuler l'opération en cours et vider le formulaire */
  annuler() {
    this.setState({
      formData: {
        id: 0,
        chpwd: true,
        login: "",
        pwd: "0000",
        nom: "",
        prenom: "",
        profil: "Guest",
        photo: "",
        telephone: "",
        email: "",
        matricule: "",
        statut: "En attente",
        adresse: "",
        dateNaiss: "",
        lieuNaiss: "",
        photo: "",
        poids: "",
        taille: "",
        sexe: "",
        photoPiece: "",
        idSite: { id: 2 }
      },
    });
    this.setState({
      visible_dlg_form: false,
      file: null,
      urlfile: null
    });
    this.setState({ showindicator: false });
  }

  /* Affichage de la boite de dialogue de confirmation de suppression */
  conf_delete_data(data) {
    this.setState({ visible_dlg_delete: true });
    this.setState({ selectedData: data });
  }

  /* Affichage de la boite de dialogue pour l'ajout d'une nouvelle commune */
  toogleDlg() {
    this.annuler();
    this.setState({ visible_dlg_form: !this.state.visible_dlg_form });
  }

  /* Zone gauche de la toolbar */
  leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.toogleDlg} style={{ width: 120, marginRight: 10 }} />
          <Button label="Impression" icon="pi pi-print" className="p-button-warning" onClick={(e) => this.setState({ visible_dlg_impression: true })} style={{ width: 130 }} />
        </div>
      </React.Fragment>
    )

  }

  /* Zone droite de la toolbar */
  rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} style={{ width: 115 }} />}
      </React.Fragment>
    )
  }

  /* Fonction de suppression du l'utilisateur sélectionné */
  toogle_data() {
    if (this.state.selectedData == null || this.state.selectedData == undefined) {
      this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne' });
    } else {
      this.appservice.save_compte(this.state.selectedData).then(reponse => {
        this.resultat(reponse);
        if (reponse.code == 200) {
          this.get_liste_data();
          if (this.state.selectedData.statut == 'Actif') {
            this.setState({ showindicator: true });
            this.appservice.send_carte(this.state.selectedData).then(reponse => {
              this.resultat(reponse);
            });
          }
        }
      });
    }
  }


  /* Colonne d'affichage de la photo */
  photoTemplate(rowData, column) {
    let imageFileName = 'nophoto.png';
    if (rowData.photo !== undefined) {
      imageFileName = rowData.photo;
    }
    var src = this.appservice.download_file(imageFileName);
    return <img src={src} alt={rowData.nom} style={{ width: '40px', height: '45px', borderRadius: 10, backgroundColor: '#f7f7f7' }} />;
  };

  /* Colonne Action du tableau contenant la liste des utilisateurs */
  actionTemplate(rowData, column) {
    const items = [
      {
        label: 'Générer badge',
        icon: 'pi pi-print',
        command: () => {
          this.conf_print_data(rowData);
        }
      },
      {
        label: 'Modifier',
        icon: 'pi pi-pencil',
        command: (e) => {
          this.edit_data(rowData);
        }
      },
      {
        label: 'Réinitialiser mot de passe',
        icon: 'pi pi-refresh',
        command: (e) => {
          rowData.action = ' réinitialiser le mot de passe de ';
          rowData.pwd = '0000';
          rowData.changer_pwd = true;
          this.conf_toogle_data(rowData);
        }
      },
    ]
    if (rowData.statut == 'Actif') {
      items.push(
        {
          label: 'Suspendre',
          icon: 'pi pi-ban',
          command: (e) => {
            rowData.action = ' suspendre le compte de ';
            rowData.statut = 'Suspendu';
            this.conf_toogle_data(rowData);
          }
        }
      )
    } else {
      items.push(
        {
          label: 'Activer',
          icon: 'pi pi-check',
          command: (e) => {
            rowData.action = ' activer le compte de ';
            rowData.statut = 'Actif';
            this.conf_toogle_data(rowData);
          }
        }
      )
    }

    return <SplitButton label="Modifier" icon="pi pi-pencil" onClick={(e) => this.edit_data(rowData)} model={items}></SplitButton>
  }

  /* Bloc visuel (propre à React) */
  render() {
    return (
      <>

        <div className="page-content bg-white">
          {/* <PageTitle parentTitle="Pages" activePage="Tableau de bord" /> */}



          <section className="content-inner" style={{ height: 1000 }}>

            <div className="container" >
              {/* Toast pour l'afficahge des messages de dialogue */}
              <div style={{ marginTop: 40 }}>
                <Toast ref={(el) => this.toast = el} position="center" />

                {this.state.visible_dlg_form == false ?
                  <>
                    {/* Toolbar contenant les boutons d'ajout, de suppression et d'exportation */}
                    <Toolbar className="mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate} style={{ paddingTop: 5, paddingBottom: 5 }} />

                    {/* Tableau contenant la liste des comptes existants dans la base */}
                    <div className="p-fluid grid formgrid" >
                      <div className="field col-4">
                        <label htmlFor="email1">Filtrer les comptes par statut </label>
                      </div>
                      <div className="field col-8">
                        <SelectButton value={this.state.selectedFilter} onChange={(e) => this.setState({ selectedFilter: e.value }, () => {
                          if (this.state.selectedFilter == 'Tous les comptes') {
                            this.setState({ liste_data: this.state.liste_all_data })
                          } else {
                            this.setState({ liste_data: this.state.liste_all_data.length > 0 ? this.state.liste_all_data.filter(function (el) { return el.statut === 'En attente'; }) : [] })
                          }
                        })} options={['Tous les comptes', 'Comptes en attente']} />
                      </div>
                    </div>

                    <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                      paginator rows={10} alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers tdLessPadding" showGridlines
                      selection={this.state.selectedData} onRowSelect={e => this.setState({ selectedData: e.data })}
                      emptyMessage="No data found" selectionMode="single"
                      onRowClick={e => this.setState({ selectedData: e.data }, () => {
                      })}>
                      <Column header="No" body={(rowData, props) => {
                        return <div>
                          <span >{props.rowIndex + 1}</span>
                        </div>
                      }} style={{ width: '3%' }} />
                      <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} exportable={false} />
                      <Column body={this.actionTemplate.bind(this)} header="Action" style={{ width: '10%' }} />
                      <Column field="nom" header="Nom" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                      <Column field="prenom" header="Prenom" filter={true} filterMatchMode='contains' />
                      <Column field="profil" header="Profile" filter={true} filterMatchMode='contains' style={{ width: '10%' }} />
                      <Column field="telephone" body={(rowData, props) => {
                        return <PhoneInput disableFormatting
                          defaultCountry="sn" inputStyle={{ width: '100%', fontSize: 14 }}
                          value={rowData.telephone}
                        />
                      }} header="Telephone" filter={true} filterMatchMode='contains' style={{ width: '16%' }} />
                      <Column field="email" header="Email" filter={true} filterMatchMode='contains' style={{ width: '20%' }} />
                      <Column field="statut" body={(rowData, column) => {
                        return <div>
                          <span style={{ color: rowData.statut == 'Actif' ? 'green' : 'red' }} >{rowData.statut}</span>
                        </div>
                      }} header="Statut" sortable filter={true} filterMatchMode='contains' style={{ width: '8%' }} />
                    </DataTable>
                  </>
                  :
                  <>
                    <nav aria-label="breadcrumb" className="breadcrumb-row">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"#"}>Compte</Link></li>{" "}
                        <li className="breadcrumb-item active" aria-current="page">{this.state.modif_data == false ? 'Ajout' : 'Modification'}</li>
                      </ul>
                    </nav>
                    <hr />
                    <center>
                      <div className="field" style={{ width: 120 }} >
                        <label>PHOTO</label>
                        <a onClick={() => this.openFileUpload()} style={{ cursor: 'pointer' }}>
                          <div style={{ height: 100, width: 95, borderRadius: 10 }} >
                            <center>
                              {this.state.urlfile == null ?
                                <div style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', display: 'flex', justifyContent: 'center', padding: 30 }} ><label style={{ color: '#003366', fontWeight: 'bold', cursor: 'pointer' }}>
                                  <i style={{ fontSize: 30, color: '#000' }} className="pi pi-camera"></i>
                                </label></div> :
                                <img src={this.state.urlfile} style={{ width: '95px', height: '100px', borderRadius: 10, backgroundColor: '#f3f7fb', border: '1px solid #CCC' }} />}
                            </center>
                          </div>
                        </a>
                        <input type="file" id="hiddenFile" style={{ visibility: 'hidden' }} onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            this.setState({ file: e.target.files[0] }, () => {
                              let url = URL.createObjectURL(this.state.file);
                              this.setState({ urlfile: url });
                            })
                          }
                        }} />
                      </div>
                    </center>
                    <div className="p-fluid grid formgrid" >
                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Nom *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.nom}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'nom', '', this.state.formData) })}
                          required autoFocus style={{ height: 35 }} />
                        {this.state.submit && !this.state.formData.nom && <small className="p-invalid" style={{ color: 'red' }}>Le nom est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Prénom *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.prenom}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'prenom', '', this.state.formData) })}
                          required style={{ height: 35 }} />
                        {this.state.submit && !this.state.formData.prenom && <small className="p-invalid" style={{ color: 'red' }}>Le prénom est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Sexe *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <div className="row" style={{ marginTop: 10 }} >
                          <div className="col-6">
                            <RadioButton inputId="fem" name="fem" value={'F'} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sexe', '', this.state.formData) })} checked={this.state.formData.sexe === 'F'} />
                            <label htmlFor="fem" style={{ marginLeft: 5 }}>Feminin</label>
                          </div>
                          <div className="col-6">
                            <RadioButton inputId="masc" name="masc" value={'M'} onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'sexe', '', this.state.formData) })} checked={this.state.formData.sexe === 'M'} />
                            <label htmlFor="masc" style={{ marginLeft: 5 }}>Masculin</label>
                          </div>
                        </div>
                        {this.state.submit && !this.state.formData.sexe && <small className="p-invalid" style={{ color: 'red' }}>Le sexe est obligatoire.</small>}
                      </div>


                      <div className="field col-3 md:col-3">
                        <label htmlFor="name1">Date de naissance * </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputMask mask="99/99/9999" id="name" value={this.state.formData.dateNaiss}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'dateNaiss', '', this.state.formData) })}
                          required style={{ height: 35 }} />
                        {this.state.submit && !this.state.formData.login && <small className="p-invalid" style={{ color: 'red' }}>La date de naissance est obligatoire</small>}
                      </div>


                      <div className="field col-3 md:col-3">
                        <label htmlFor="name1">Lieu de naissance * </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name1" value={this.state.formData.lieuNaiss}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'lieuNaiss', '', this.state.formData) })}
                          required style={{ height: 35 }} />
                        {this.state.submit && !this.state.formData.lieuNaiss && <small className="p-invalid" style={{ color: 'red' }}>Le lieu de naissance est obligatoire</small>}
                      </div>


                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Téléphone *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        {/*  <InputMask id="tel" mask="999999999" value={this.state.formData.telephone}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'telephone', '', this.state.formData) })}
                          required keyfilter='pint' /> */}
                        <PhoneInput
                          defaultCountry="sn" inputStyle={{ width: '100%', fontSize: 14 }}
                          countrySelectorStyleProps={{ height: 100 }} dialCodePreviewStyleProps={{ height: 100 }}
                          value={this.state.formData.telephone}
                          onChange={(e) => this.setState({ formData: this.appservice.onIntlTelInputChange(e, 'telephone', this.state.formData) })}
                        />
                        {this.state.submit && !this.state.formData.telephone && <small className="p-invalid" style={{ color: 'red' }}>Le téléphone est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Email *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.email}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'email', '', this.state.formData) })}
                          required style={{ height: 35 }} />
                        {this.state.submit && !this.state.formData.email && <small className="p-invalid" style={{ color: 'red' }}>L'email est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Profil * </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <Dropdown autoWidth={false} value={this.state.formData.profil} filter={true} filterBy='label' filterMatchMode='contains'
                          options={this.state.liste_profil}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'profil', '', this.state.formData) })}
                          appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.profil })} />
                        {this.state.submit && !this.state.formData.profil && <small className="p-invalid" style={{ color: 'red' }}>Le profil est obligatoire</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Adresse </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="name" value={this.state.formData.adresse}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'adresse', '', this.state.formData) })}
                          required style={{ height: 35 }} />
                      </div>

                      


                      {/*  <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Poids </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputNumber min={30} max={150} keyfilter='pnum' id="name" value={this.state.formData.poids}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'poids', '', this.state.formData) })}
                        />
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Taille </label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputNumber min={1} max={2} keyfilter='pnum' id="name" value={this.state.formData.taille}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'taille', '', this.state.formData) })}
                        />
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="email1">Studio *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <Dropdown autoWidth={false} value={this.state.formData.idSite.id} filter={true} filterBy='label' filterMatchMode='contains'
                          options={this.state.liste_agence}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'idSite', 'fk', this.state.formData) })}
                          appendTo={document.body} className={classNames({ 'p-invalid': this.state.submit && !this.state.formData.idSite.id })} />
                        {this.state.submit && !this.state.formData.idSite && <small className="p-invalid" style={{ color: 'red' }}>Le studio est obligatoire.</small>}
                      </div>

                      <div className="field col-3 md:col-3">
                        <label htmlFor="login">Login *</label>
                      </div>
                      <div className="field col-9 md:col-3">
                        <InputText id="login" value={this.state.formData.login}
                          onChange={(e) => this.setState({ formData: this.appservice.onInputChange(e, 'login', '', this.state.formData) })}
                          required />
                        {this.state.submit && !this.state.formData.login && <small className="p-invalid" style={{ color: 'red' }}>Le login est obligatoire.</small>}
                      </div>
 */}
                    </div>
                    <br />
                    <center>
                      <>
                        <Button label="Annuler" icon="pi pi-times" className="p-button-secondary" style={{ width: 200 }} onClick={(e) => this.annuler()} />
                        <Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={(e) => this.enreg_data()} style={{ width: 200, marginLeft: 20 }} />
                      </>
                    </center>
                  </>
                }
                <>
                </>


                {/* Boite de dialogue de confirmation de suppression */}
                <Dialog visible={this.state.visible_dlg_toogle} style={{ width: '450px' }} header="Confirmation" modal
                  footer={
                    <>
                      <Button label="Non" icon="pi pi-times" className="p-button-secondary" onClick={() => {
                        this.setState({ visible_dlg_toogle: false });
                      }} />
                      <Button label="Oui" icon="pi pi-check" className="p-button-danger" onClick={() => {
                        this.setState({ visible_dlg_toogle: false });
                        this.toogle_data();
                      }} />
                    </>
                  } onHide={(e) => this.setState({ visible_dlg_toogle: false })}>
                  <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {this.state.selectedData && <span>Voulez-vous vraiment {this.state.selectedData.action}  <label style={{ fontWeight: 'bold' }}>{this.state.selectedData.prenom} {this.state.selectedData.nom}</label> ?</span>}
                  </div>
                </Dialog>

                {/* Boite de dialogue d'affichage des détails pour impression en bloc */}
                <Dialog baseZIndex={100000} visible={this.state.visible_dlg_impression} style={{ width: '80%' }} header="Edition des cartes" modal onHide={(e) => this.setState({ visible_dlg_impression: false })} appendTo={document.body}>
                  {/* Tableau contenant la liste des cartes à imprimer */}
                  <DataTable ref={(el) => { this.dt = el; }} value={this.state.liste_data} resizableColumns="scroll"
                    paginator rows={8} showGridlines className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                    selection={this.state.liste_selected_comptes} onSelectionChange={e => this.setState({ liste_selected_comptes: e.value })}
                    emptyMessage="No data found" alwaysShowPaginator={false}
                    footer={
                      <center>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                          <Button label="Générer" icon="pi pi-print" className="p-button-warning"
                            onClick={(e) => {
                              this.conf_print_bloc()
                            }} style={{ width: 200 }} />
                        }
                      </center>
                    }>
                    <Column selectionMode="multiple" headerStyle={{ width: '3%' }} />
                    <Column header="No" body={(rowData, props) => {
                      return <div>
                        <span >{props.rowIndex + 1}</span>
                      </div>
                    }} style={{ width: '3%' }} />
                    <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} exportable={false} />
                    <Column field="nom" header="Nom" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                    <Column field="prenom" header="Prenom" filter={true} filterMatchMode='contains' />
                    <Column field="profil" header="Profile" filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                    <Column field="telephone" body={(rowData, props) => {
                      return <PhoneInput
                        defaultCountry="sn" inputStyle={{ width: '100%', fontSize: 14 }}
                        countrySelectorStyleProps={{ height: 100 }} dialCodePreviewStyleProps={{ height: 100 }}
                        value={rowData.telephone}
                      />
                    }} header="Telephone" filter={true} filterMatchMode='contains' style={{ width: '17%' }} />
                    <Column field="statut" body={(rowData, column) => {
                      return <div>
                        <span style={{ color: rowData.statut == 'Actif' ? 'green' : 'red' }} >{rowData.statut}</span>
                      </div>
                    }} header="Statut" filter={true} filterMatchMode='contains' style={{ width: '8%' }} />
                  </DataTable>
                  <br />
                </Dialog>
              </div>

            </div>
          </section>

        </div>
      </>
    );
  }
}
